import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import MooneraAlert from "../../../components/MooneraAlert";
import dashboardProductsService from "../../../services/dashboard-products.service";
import { IProduct } from "../../../interfaces/IProduct";
import MooneraProduct from "../../../components/MooneraProduct";

export default function DashboardProduct() {
  let { productID } = useParams();
  useEffect(() => {
    dashboardProductsService.getProduct(productID ?? '').then(
      (response) => {
        setProduct(response.product);
      },
      (error) => {
        // dispatch error alert
      }

    );
  });
  const [product, setProduct] = useState<IProduct | undefined >(undefined);


  if (product === undefined) {
    return ( 
      <></>
    );
  }

  return ( 
    <>
      <MooneraAlert text={'This is a preview of your product. This is how it appears on the site.'} type={'ALERT_INFO'} />
      <MooneraProduct product={product} />
    </>
  );
}