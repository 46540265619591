import { useState, useEffect } from "react";
import MooneraButton from '../../components/MooneraButton';
import MooneraEmptyState from '../../components/MooneraEmptyState';
import MooneraPageHeader from '../../components/MooneraPageHeader';
import MooneraText from '../../components/MooneraText';
import MooneraLink from '../../components/MooneraLink';
import dashboardProductsService from "../../services/dashboard-products.service";
import { Link } from "react-router-dom";
import { IProduct } from "../../interfaces/IProduct";

export default function DashboardScreen() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    dashboardProductsService.getAllProducts().then(
      (response) => {
        setProducts(response.products);
      },
      (error) => {
        // dispatch error alert
      }

    );
  });

  return ( 
    <div className="max-w-7xl mx-auto mt-2">
      <MooneraPageHeader
        headerComponent={ <MooneraText text={'Products '} level={'secondary'}/>}
        subHeaderComponent={<MooneraText 
          text={'From here you will be able to create and manage your products. '} 
          level={'p-secondary'}/>}
        primaryCTAComponent={<MooneraLink text={'Create new product'} href={'/dashboard/products/new'} /> }
      />
      { products.length === 0 ? <MooneraEmptyState 
        textComponent={<MooneraText 
            text={'You have zero products. Get started by creating a new product. '} 
            level={'p-secondary'}/>}
        primaryCTAComponent={<MooneraLink text={'Create new product'} href={'/dashboard/products/new'} /> }
        /> :
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Description
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Minimum order qty
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Starting price
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Preview
                      </th>
                    
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Manage
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {products.map((product: IProduct) => (
                      <tr key={product.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={product.main_photo} alt="" />
                            </div>
                            <div className="ml-4">
                              
                              <div className="font-medium text-gray-900">{product.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500 text-left">
                          <div className="text-gray-900 ">
                            {product.status === 'published' ?
                            <span className="rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{product.status}</span>
                            : <span className="rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">{product.status}</span>
                            }
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500 text-left">
                          <div className="text-gray-900">{product.description}</div>
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500 text-left">
                          <div className="text-gray-900">{product.minimum_order_quantity}</div>
                        </td>
                      
                        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500 text-left">{product.starting_price}</td>
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 text-indigo-600 hover:text-indigo-900">
                          <Link to={`/dashboard/products/${product.id}`}>Preview</Link>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 text-indigo-600 hover:text-indigo-900">
                          <Link to={`/dashboard/products/${product.id}/edit`} state={{ product: product }}>Manage</Link>
                         
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}