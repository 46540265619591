import React from "react";

type StyleType = 
  | 'withBorder'
  | 'withoutBorder'

interface IProps {
  children: React.ReactNode
  styleType?: StyleType
}

const MooneraCard: React.FC<IProps> = ({children, styleType="withBorder"}) => {

  switch (styleType) {
    case 'withBorder': 
      return (
        <div className="mt-4 sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {children}
          </div>
        </div>
      );
    case 'withoutBorder':
      return (
        <div className="mt-4 sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
            {children}
          </div>
        </div>
      );
  } 
};

export default MooneraCard;
