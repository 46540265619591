import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { updateProduct } from "../../../actions/products";
import { IProduct } from "../../../interfaces/IProduct";

import MooneraButton from '../../../components/MooneraButton';
import MooneraText from '../../../components/MooneraText';
import MooneraTextArea from "../../../components/MooneraTextArea";
import MooneraTextInput from '../../../components/MooneraTextInput';

interface CustomState {
  product: IProduct
}

export default function DashboardProductEdit() {
  const [name, setName] = useState("");
  const [id, setID] = useState("");
  const [description, setDescription] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomState;
  
  useEffect(() => {
    const { product } = state;
    setID(product.id);
    setName(product.name);
    setDescription(product.description);
    setStartingPrice(String(product.starting_price));
    setMinimumOrderQuantity(String(product.minimum_order_quantity));
  }, [state]);
  

  const dispatch = useDispatch();
  const onNameChange = (e: any) => {
    const name = e.target.value;
    setName(name);
  };
  const onDescriptionChange = (e: any) => {
    const description = e.target.value;
    setDescription(description);
  };
  const onStartingPriceChange = (e: any) => {
    const startingPrice = e.target.value;
    setStartingPrice(startingPrice);
  };
  const onMinimumOrderQuantityChange = (e: any) => {
    const minimumOrderQuantity = e.target.value;
    setMinimumOrderQuantity(minimumOrderQuantity);
  };
  
  const handleUpdate = (e: any) => {
    e.preventDefault();
    const updateAction = updateProduct(id, name, description, minimumOrderQuantity, startingPrice, 'published')
    dispatch<any>(updateAction)
      .then(() => {
        navigate('/dashboard')
      })
      .catch(() => {
      });
    
  };

  const handleUpdateDraft = (e: any) => {
    e.preventDefault();
    const updateAction = updateProduct(id, name, description, minimumOrderQuantity, startingPrice, 'draft')
    dispatch<any>(updateAction)
      .then(() => {
        navigate('/dashboard')
      })
      .catch(() => {
      });
    
  };
  

  return ( 
    <>
      <form className="mx-16 mt-8 space-y-8 divide-y divide-gray-200" action="#" method="POST">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 text-left">
          <div>
            <div>
              <MooneraText text={'Manage Product'} level={'secondary'}/>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                  <MooneraText text={'From here your will be able to manage your product details.'} level={'p-secondary'}/>
                  <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center w-64">
                    <MooneraButton onClick={handleUpdateDraft} text={'Save Draft'} level={'secondary'} />
                    <MooneraButton onClick={handleUpdate} text={'Update & Publish'} level={'primary'} />
                  </div>
                </div>
            </div>
              <MooneraTextInput 
                label={'Product name'}
                htmlFor={'name'}
                id={'name'}
                name={'name'}
                type={'text'}
                required={true}
                value={name}
                onChange={onNameChange}
                styleType={'labels-on-left'}
              />
              <MooneraTextArea
                label={'Description'}
                htmlFor={'description'}
                id={'description'}
                name={'description'}
                required={true}
                value={description}
                onChange={onDescriptionChange}
                styleType={'labels-on-left'}
              />
              <MooneraTextInput 
                label={'Mimum order quantity'}
                htmlFor={'minimum-order-qty'}
                id={'minimum-order-qty'}
                name={'minimum-order-qty'}
                type={'number'}
                required={true}
                value={minimumOrderQuantity}
                onChange={onMinimumOrderQuantityChange}
                styleType={'labels-on-left'}
              />
              <MooneraTextInput 
                label={'Starting price'}
                htmlFor={'starting-price'}
                id={'starting-price'}
                name={'starting-price'}
                type={'number'}
                required={true}
                value={startingPrice}
                onChange={onStartingPriceChange}
                styleType={'labels-on-left'}
              />
        </div>
      </div>
    </form>
    </>
  );
}