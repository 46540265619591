import React from "react";

type StyleType = 
  | 'labels-on-left'

interface IProps {
  label: string;
  htmlFor: string;
  id: string;
  name: string;
  required: boolean;
  value: string;
  onChange: any;
  styleType?: StyleType;
  rows?: number
}

const MooneraTextArea: React.FC<IProps> = ({ label, id, name, value, onChange, required=false, htmlFor, styleType='labels-on-left', rows=3 }) => {

  switch (styleType) {
    case 'labels-on-left':
      return (
        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {label}  
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id={id}
                name={name}
                rows={rows}
                className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                defaultValue={value}
                onChange={onChange}
                required={required}
              />
            </div>
        </div>
      </div>
    );
  } 
};


export default MooneraTextArea;
