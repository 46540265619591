import React from "react";

interface IProps {
  text: string;
  href: string;
}

const MooneraLink: React.FC<IProps> = ({ text, href }) => {

  return (
    <div className="text-sm">
      <a href={href} className="font-medium text-indigo-600 hover:text-indigo-500">
        {text}
      </a>
    </div>
  );
};


export default MooneraLink;
