import React from "react";

interface IProps {
  label: string;
  htmlFor: string;
  id: string;
  name: string;
  value: string;
  onChange: any;
  options: any;
}

const MooneraInputSelector: React.FC<IProps> = ({ label, id, name, value, onChange, htmlFor, options}) => {
  return(
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <select
          id={id}
          name={name}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={value}
          value={value}
          onChange={onChange}
        >
          {options.map((option: string) => (
            <option>{option}</option>
          ))}
        </select>
      </div>
    </div>
  );
};


export default MooneraInputSelector;
