import axios from "axios";
import { API_URL } from "../helpers/api-url";

const getAllProducts = () => {
  return axios
    .get(API_URL + "/products")
    .then((response) => {
      return response.data;
    });
};

const getProduct = (productID: string) => {
  return axios
    .get(API_URL + (`/products/${productID}`  ))
    .then((response) => {
      return response.data;
    });
};

const productSearch = (text: string) => {
  return axios
    .get(API_URL + `/product_search?text=${text}`)
    .then((response) => {
      return response.data;
    });
};

export default {
  productSearch,
  getAllProducts,
  getProduct
}
