
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MooneraText from '../../components/MooneraText';
import MooneraButton from '../../components/MooneraButton';
import MooneraTextInput from '../../components/MooneraTextInput';
import dashboardUserService from "../../services/dashboard-user.service";
import { updateAccount} from "../../actions/account";

export default function DashboardAccountScreen() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    dashboardUserService.getAccount().then(
      (response) => {
        const user = response.user;
        if (user !== null && user !== undefined) {
          setFirstName(user.first_name);
          setLastName(user.last_name);
          setEmail(user.email);
        }
      },
      (_error) => {}
    );
  }, []);

  const dispatch = useDispatch();
  const onFirstNameChange = (e: any) => {
    const name = e.target.value;
    setFirstName(name);
  };
  const onLastNameChange = (e: any) => {
    const name = e.target.value;
    setLastName(name);
  };
  const onEmailChange = (e: any) => {
    const email = e.target.value;
    setEmail(email);
  };
  
  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("user[first_name]", firstName);
    formData.append("user[last_name]", lastName);
    formData.append("user[email]", email);
    

    const saveAction = updateAccount(formData);

    dispatch<any>(saveAction)
      .then(() => {
      })
      .catch(() => {
      });
  };

  return (
    <div className="mx-16 mt-8">
      <form className=" space-y-8 " action="#" method="POST" onSubmit={handleSave}>
        <div className="space-y-8  sm:space-y-5 text-left">
          <div>
            <MooneraText text={'Account Information'} level={'tertiary'}/>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <MooneraText text={'From here you will be able to manage information related to your account'} level={'p-secondary'}/>
              <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center w-32">
                <MooneraButton text={'Save'} level={'primary'} />
              </div>
            </div>
          </div>
            <MooneraTextInput 
              label={'First name'}
              htmlFor={'first-name'}
              id={'first-name'}
              name={'first-name'}
              type={'text'}
              required={true}
              value={firstName}
              onChange={onFirstNameChange}
              styleType={'labels-on-left'}
            />
            <MooneraTextInput 
              label={'Last name'}
              htmlFor={'last-name'}
              id={'last-name'}
              name={'last-name'}
              type={'text'}
              required={true}
              value={lastName}
              onChange={onLastNameChange}
              styleType={'labels-on-left'}
            />
            <MooneraTextInput 
              label={'Email'}
              htmlFor={'email'}
              id={'email'}
              name={'email'}
              type={'email'}
              value={email}
              onChange={onEmailChange}
              styleType={'labels-on-left'}
            />
        </div>
    </form>
    
    <div className="mt-16 space-y-8  sm:space-y-5 text-left sm:border-t sm:border-gray-200 sm:pt-5">
      <div>
        <MooneraText text={'Change Password'} level={'tertiary'}/>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <MooneraText text={'You will receive an email with further instructions.'} level={'p-secondary'}/>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center w-64">
            <MooneraButton text={'Change Password'} level={'primary'} />
          </div>
        </div>
      </div>
    </div>  

    <div className="mt-16 mb-16 space-y-8  sm:space-y-5 text-left sm:border-t sm:border-gray-200 sm:pt-5">
      <div>
        <MooneraText text={'Close Account'} level={'tertiary'}/>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <MooneraText text={'To close account, please email support@moonera.com with your account email. A representative will reach out to you.'} level={'p-secondary'}/>
        </div>
      </div>
    </div>    
  </div>
    
  );
}