import React from "react";

interface IProps {
  headerComponent: React.ReactNode
  subHeaderComponent: React.ReactNode
  primaryCTAComponent?: React.ReactNode
}

const MooneraPageHeader: React.FC<IProps> = ({headerComponent, subHeaderComponent, primaryCTAComponent}) => {

  return (
    <div className="bg-white px-4 py-2 border-b border-gray-200 sm:px-6">
      <div className="ml-2 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap text-left">
        <div className=" mt-4 text-left">
          {headerComponent}
          {subHeaderComponent}
        </div>
        { primaryCTAComponent ? 
          <div className="ml-4 mt-4 flex-shrink-0">
            {primaryCTAComponent}
          </div> : null 
        }
      </div>
    </div>
  );
};


export default MooneraPageHeader;
