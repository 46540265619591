import { ALERT_SUCCESS, ALERT_ERROR, ALERT_CLEAR, ALERT_INFO } from "../actions/types";
const initialState = {};

export function alert(state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case ALERT_SUCCESS:
      return { message: payload, type: ALERT_SUCCESS };
    case ALERT_ERROR:
      return { message: payload, type: ALERT_ERROR };
    case ALERT_CLEAR:
      return { message: null, type: ALERT_CLEAR };
    case ALERT_INFO:
      return { message: payload, type: ALERT_INFO };
    default:
      return state;
  }
}