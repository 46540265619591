import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';

import { login } from "../actions/auth";

import MooneraButton from '../components/MooneraButton';
import MooneraCard from '../components/MooneraCard';
import MooneraCheckbox from '../components/MooneraCheckBox';
import MooneraLink from '../components/MooneraLink';
// import MooneraSocialButton from '../components/MooneraSocialButton';
import MooneraText from '../components/MooneraText';
import MooneraTextInput from '../components/MooneraTextInput';

export default function SignInScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector( (state: any)=> state.auth);

  const dispatch = useDispatch();
  const onChangeUsername = (e: any) => {
    const username = e.target.value;
    setUsername(username);
  };
  const onChangePassword = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };


  if (isLoggedIn) {
    return <Navigate to="/dashboard/company" replace={true}  />;
  }
  
  const handleLogin = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const loginAction = login(username, password);
    dispatch<any>(loginAction)
      .then(() => {
        return <Navigate to="/dashboard/company" replace={true}  />;
      })
      .catch(() => {
        setLoading(false);
      });
  };
  

  return ( 
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <MooneraText text={'Sign in to your account'} level={'secondary'}/>
          <MooneraLink text={'or create a new account'} href={'/signup'} />
      
          <MooneraCard>
            <form className="space-y-6" action="#" method="POST" onSubmit={handleLogin}>
              <MooneraTextInput 
                label={'Email Address'}
                htmlFor={'email'}
                id={'email'}
                name={'email'}
                type={'email'}
                autocomplete={'email'}
                required={true}
                value={username}
                onChange={onChangeUsername}
              />
              <MooneraTextInput 
                label={'Password'}
                htmlFor={'password'}
                id={'password'}
                name={'password'}
                type={'password'}
                autocomplete={'current-password'}
                required={true}
                value={password}
                onChange={onChangePassword}
              />
      
              <div className="flex items-center justify-between">
                <MooneraCheckbox 
                  label={'Remember me'}
                  htmlFor={'remember-me'}
                  id={'remember-me'}
                  name={'remember-me'}
                />
                <MooneraLink text={'Forgot your password?'} href={'forgot-password'} />
              </div>
              <MooneraButton text={'Sign in'} level={'primary'} />
            </form>

              {/* <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
                
                <div className="mt-6 grid gap-3">
                  <MooneraSocialButton 
                    type={'facebook'}
                    alt={'Sign in with Facebook'}
                    href={'#'}
                    variety={'button'}
                  />
                </div>
              </div> */}
          </MooneraCard>
        </div>
      </div>
    </>
  );
}