import React from "react";

interface IProps {
  label: string;
  htmlFor: string;
  id: string;
  name: string;
  required?: boolean;
}

const MooneraCheckbox: React.FC<IProps> = ({label, id, name, required=false, htmlFor}) => {

  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="checkbox"
        required={required}
        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      />
      <label htmlFor={htmlFor} className="ml-2 block text-sm text-gray-900">
        {label}
      </label>
    </div>
  );
};


export default MooneraCheckbox;
