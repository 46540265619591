import React from "react";

export type levelType = 
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'meta'
  | 'p-secondary'

interface IProps {
  text: string;
  level: levelType;
}

const MooneraText: React.FC<IProps> = ({ text, level = 'secondary' }) => {

  switch (level) {
    case 'primary':
      return (
        <h1 className="mt-6 text-3xl font-extrabold text-gray-900">{text}</h1>
      );
    case 'secondary':
      return (
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{text}</h2>
      );
    case 'tertiary':
      return (
        <h2 className="text-lg leading-6 font-medium text-gray-900">{text}</h2>
      );
    case 'p-secondary':
      return (<p className="mt-1 text-sm text-gray-500">{text}</p>)
    default: 
      return (<p className="mt-1 text-sm text-gray-500">{text}</p>)
  }
};


export default MooneraText;
