import { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ICompany } from "../interfaces/ICompany";
import {
  MailIcon,
  PhoneIcon,
  EyeIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import MooneraText from "../components/MooneraText";
import companiesService from "../services/companies.service";
import { useNavigate } from "react-router-dom";

interface IProps {
  companies: ICompany[];
  handleFiltersSearch: any;
}

interface ISearchFilter {
  value: string;
  label: string;
}

const buildFilters = (filters: Array<string>) => {
  return filters.map((filter: string) => {
    return {
      value: filter,
      label: filter,
    };
  });
};

const CompaniesScreen: React.FC<IProps> = ({
  companies,
  handleFiltersSearch,
}) => {
  const [existingSectors, setExistingSectors] = useState<Array<ISearchFilter>>(
    []
  );
  const [existingCapabilities, setExistingCapabilities] = useState<
    Array<ISearchFilter>
  >([]);
  const [selectedSectors, setSelectedSectors] = useState(new Set());
  const [selectedCapabilities, setSelectedCapabilities] = useState(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    companiesService.companySearchFilters().then(
      (response) => {
        setExistingSectors(buildFilters(response.filters.sectors));
        setExistingCapabilities(buildFilters(response.filters.capabilities));
      },
      (error) => {
        // dispatch error alert
      }
    );
  }, []);
  const filters = [
    {
      id: "industry",
      name: "Industry",
      options: existingSectors,
    },
    {
      id: "category",
      name: "Category",
      options: existingCapabilities,
    },
  ];

  const onFiltersChange = (e: any) => {
    const filterVal = e.target.value;
    const filterChecked = e.target.checked;
    const filterName = e.target.name;

    if (filterName === "industry[]") {
      if (filterChecked) {
        selectedSectors.add(filterVal);
      } else {
        selectedSectors.delete(filterVal);
      }
      setSelectedSectors(selectedSectors);
    }

    if (filterName === "category[]") {
      if (filterChecked) {
        selectedCapabilities.add(filterVal);
      } else {
        selectedCapabilities.delete(filterVal);
      }
      setSelectedCapabilities(selectedCapabilities);
    }

    handleFiltersSearch(selectedCapabilities, selectedSectors);
  };

  return (
    <div className="text-left mx-auto max-w-7xl overflow-hidden pb-8 pr-4 px-4">
      <MooneraText text="Find your supplier" level="primary" />
      <MooneraText
        text="A network of trusted suppliers in the Americas"
        level="tertiary"
      />
      <br></br>
      <div className="grid lg:grid-cols-5 sm:grid-cols-1">
        <form className="hidden lg:block py-6">
          {filters.map((section) => (
            <Disclosure
              as="div"
              key={section.id}
              className="border-b border-gray-200 py-6"
            >
              {({ open }) => (
                <>
                  <h3 className="-my-3 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel unmount={false} className="pt-6">
                    <div className="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            defaultValue={option.value}
                            type="checkbox"
                            onChange={onFiltersChange}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </form>
        <div className="lg:pl-8 mt-4 lg:col-start-2 lg:col-span-4">
          {companies.map((company: ICompany) => (
            <div
              key={company.email}
              className="mt-4 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <img
                  className="w-20 h-20 bg-gray-300 rounded-full flex-shrink-0"
                  src={company.logo_url}
                  alt=""
                />

                <div className="flex-1 truncate">
                  <div className="flex text-left  space-x-3">
                    <h2 className="text-gray-900 text-sm font-medium truncate">
                      {company.name}
                    </h2>
                  </div>
                  <p className="text-left mt-1 text-gray-500 text-sm truncate">
                    {company.description}
                  </p>
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="w-0 flex-1 flex">
                    <a
                      href={`mailto:${company.email}`}
                      className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                    >
                      <MailIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Email</span>
                    </a>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <a
                      href={`tel:${company.phone_number}`}
                      className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                      <PhoneIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Call</span>
                    </a>
                  </div>
                  <div className="w-0 flex-1 flex">
                    <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                      <button
                        onClick={() => {
                          navigate(`/company/${company.id}/${company.name}/`);
                        }}
                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                      >
                        <EyeIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="ml-3">View Profile</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompaniesScreen;
