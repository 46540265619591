import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './App.css';
import { BrowserRouter, Routes, Outlet, Route } from 'react-router-dom';

import MooneraNavigation from './components/navigation/MooneraNavigation';
import MooneraFooter from './components/navigation/MooneraFooter';
import RequireAuth from "./helpers/require-auth";
import MooneraAlert from "./components/MooneraAlert";

import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';

import CompanyScreen from './screens/CompanyScreen';

import ProductScreen from './screens/ProductScreen';
import ProductsScreen from './screens/ProductsScreen';
import DirectoryScreen from './screens/DirectoryScreen';

import DashboardScreen from './screens/dashboard/DashboardScreen';
import DashboardCompanyScreen from "./screens/dashboard/DashboardCompanyScreen";
import DashboardAccountScreen from "./screens/dashboard/DashboardAccountScreen";
import DashboardProductNew from "./screens/dashboard/products/DashboardProductNew";
import DashboardProductEdit from "./screens/dashboard/products/DashboardProductEdit";
import DashboardProduct from "./screens/dashboard/products/DashboardProduct";
import productsService from "./services/products.service";
import { IProduct } from './interfaces/IProduct';
import { ICompany } from './interfaces/ICompany';
import companiesService from "./services/companies.service";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";

function App() {
  let alert = useSelector((state: any) => state.alert);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  useEffect(() => {
    companiesService.getAllCompanies().then(
      (response) => {
        setCompanies(response.companies);
      },
      (error) => {}
    );

    productsService.getAllProducts().then(
      (response) => {
        setProducts(response.products);
      },
      (error) => {}
    );
  },[]);

  const handleSearch = (query: string) => {
    companiesService.companySearch(query, [], []).then(
      (response) => {
        console.log("search results , ", response.companies)
        setCompanies(response.companies);
      },
      (error) => {}
    ); 
  };

  const handleFiltersSearch = (capabilities: any, sectors: any) => {
    companiesService.companyFiltersSearch(Array.from(capabilities), Array.from(sectors)).then(
      (response) => {
        setCompanies(response.companies);
      },
      (error) => {}
    ); 
  };


  return (
    <div className="App">
      { alert && alert.message && <MooneraAlert text={alert.message} type={alert.type} /> }

      <BrowserRouter>
        <MooneraNavigation handleSearch={handleSearch}/>
          <Routes>
            <Route path="/" element={<DirectoryScreen companies={companies} handleFiltersSearch={handleFiltersSearch}/>} />

            <Route path="signin" element={<SignInScreen />} />
            <Route path="signup" element={<SignUpScreen />} />
            <Route path="forgot-password" element={<ForgotPasswordScreen />} />
            <Route path="reset-password" element={<ResetPasswordScreen />} />

            <Route path="products" element={<ProductsScreen products={products}/>} />
            <Route path="products/:productID" element={<ProductScreen />} />
            
            <Route path="company/:companyID/:companyName" element={<CompanyScreen />} />

            <Route path="directory" element={<DirectoryScreen companies={companies} handleFiltersSearch={handleFiltersSearch}/>} />
            
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route index element={<DashboardScreen />} />
              <Route
                path="company"
                element={
                    <DashboardCompanyScreen />
                }
              />
              <Route
                path="account"
                element={
                  <DashboardAccountScreen />
                }
              />
              <Route
                path="products/new"
                element={
                  <DashboardProductNew />
                }
              />
              <Route
                path="products/:productID"
                element={
                  <DashboardProduct/>
                }
              />
              <Route
                path="products/:productID/edit"
                element={
                  <DashboardProductEdit/>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      <MooneraFooter />
                
    </div>
  );
}

export default App;
