import React from "react";
import { useAuth } from "../../hooks/useAuth";
import MooneraDashboardNavigation from "./MooneraDashboardNavigation";
import MooneraStoreNavigation from "./MooneraStoreNavigation";

interface IProps {
  handleSearch: any,
}

const MooneraNavigation: React.FC<IProps>= ({handleSearch}) => {
  const auth = useAuth();

  if (auth) {
    return <MooneraDashboardNavigation />
  }

  return <MooneraStoreNavigation handleSearch={handleSearch}/>
};

export default MooneraNavigation;
