import { CheckCircleIcon, XCircleIcon, ExclamationIcon } from '@heroicons/react/solid'

export type alertType = 
  | 'ALERT_CLEAR'
  | 'ALERT_SUCCESS'
  | 'ALERT_ERROR'
  | 'ALERT_WARNING'
  | 'ALERT_INFO'

interface IProps {
  text: string;
  type: alertType
}     

const MooneraAlert: React.FC<IProps> = ({ text, type }) => {
  const alertSuccessContent = (
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-green-800">{text}</p>
      </div>
    </div>);
  
  const alertErrorContent = (
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-red-800">{text}</p>
      </div>
    </div>);
  
  const alertWarningContent = (
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-yellow-800">{text}</p>
      </div>
    </div>);
  
  const alertInfoContent = (
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-purple-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-purple-800">{text}</p>
      </div>
    </div>);
  
  switch(type) {
    case 'ALERT_ERROR':
      return (<div className="rounded-md bg-red-50 p-4">
        {alertErrorContent}
      </div>);
    case 'ALERT_WARNING':
      return (<div className="rounded-md bg-yellow-50 p-4">
        {alertWarningContent}
      </div>);
    case 'ALERT_SUCCESS':
      return (<div className="rounded-md bg-green-50 p-4">
        {alertSuccessContent}
      </div>);
     case 'ALERT_INFO':
      return (<div className="rounded-md bg-purple-50 p-4">
        {alertInfoContent}
      </div>);
    default:
      return (<div className="rounded-md bg-green-50 p-4">
        {alertWarningContent}
      </div>);
  }
    
  
}

export default MooneraAlert;