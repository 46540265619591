import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from 'react-router-dom';

import { resetPassword } from "../actions/auth";

import MooneraButton from '../components/MooneraButton';
import MooneraCard from '../components/MooneraCard';
import MooneraLink from '../components/MooneraLink';
// import MooneraSocialButton from '../components/MooneraSocialButton';
import MooneraText from '../components/MooneraText';
import MooneraTextInput from '../components/MooneraTextInput';

export default function ResetPasswordScreen() {
  const [password, setPassword] = useState("");
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("t");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector( (state: any)=> state.auth);

  const dispatch = useDispatch();
  const onChangePassword = (e: any) => {
    const password = e.target.value;
    setPassword(password);
  };

  if (token === null) {
    return <></>;
  }; 
  if (isLoggedIn) {
    return <Navigate to="/dashboard/company" replace={true}  />;
  }
  
  const handlePasswordResetRequest = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const resetPasswordAction = resetPassword(password, token);
    dispatch<any>(resetPasswordAction)
      .then(() => {
        return <Navigate to="/signin" replace={true}  />;
      })
      .catch(() => {
        setLoading(false);
      });
  };
  

  return ( 
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <MooneraText text={'Forgot your password?'} level={'secondary'}/>
          <MooneraLink text={'No worries! We are here to help!'} href={'/signup'} />
      
          <MooneraCard>
            <form className="space-y-6" action="#" method="POST" onSubmit={handlePasswordResetRequest}>
              <MooneraText text={'Type in your new password.'} level={'p-secondary'} />
              <MooneraTextInput 
                label={'New Password'}
                htmlFor={'password'}
                id={'password'}
                name={'password'}
                type={'password'}
                autocomplete={'password'}
                required={true}
                value={password}
                onChange={onChangePassword}
              />
      
              <MooneraButton text={'Update Password'} level={'primary'} />
            </form>
          </MooneraCard>
        </div>
      </div>
    </>
  );
}