import axios from "axios";
import { API_URL } from "../helpers/api-url";
import authHeader from "../helpers/auth-header";

const getAccount = () => {
  return axios
    .get(API_URL + "/dashboard/user", { headers: authHeader()  })
    .then((response) => {
      return response.data;
    });
};


const updateAccount = (formData: any) => { 
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
  return axios
    .put(API_URL + `/dashboard/user`,  formData, { headers: authHeader()  })
    .then((response) => {
      return response.data;
    });
};

export default {
  getAccount,
  updateAccount,
}
