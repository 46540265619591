import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PencilIcon, UserIcon, SearchIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline'
import MooneraText from '../../components/MooneraText';
import MooneraButton from '../../components/MooneraButton';
import MooneraTextInput from '../../components/MooneraTextInput';
import MooneraFileInput from '../../components/MooneraFileInput';
import dashboardCompanyService from "../../services/dashboard-company.service";
import { createCompany, updateCompany } from "../../actions/company";
import MooneraTextArea from "../../components/MooneraTextArea";
import MooneraInputSelector from "../../components/MooneraInputSelector";
import MooneraGuidedPopOver from "../../components/MooneraGuidedPopOver";
import { useOnboardingTour } from "../../hooks/useOnboardingTour";
import MooneraLogo from "../../components/MooneraLogo";

export default function DashboardCompanyScreen() {
  const [saveActionState, setActionState] = useState('create');
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [displayLogo, setDisplayLogo] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sector, setSector] = useState("");
  const [capabilities, setCapabilities] = useState("");
  const [certifications, setCertifications] = useState("");
  const [trademarks, setTrademarks] = useState("");
  const [patents, setPatents] = useState("");
  const [businessHours, setBusinessHours] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [yearEstablished, setYearEstablished] = useState("");
  const [location, setLocation] = useState("");
  const [openTour, setOpenTour] = useState(false);

  const { currStep, prevStep, nextStep, stepNum, reStartTour, getTourCompletedFromLocalStorage, markTourAsDone } = useOnboardingTour(
    [
      {
        title: "",
        content:
          <>
          Moonera is the place where you will find verified LATAM suppliers for your business.
          <br/><br/> 
          You will be able to open this tour again from the help icon in the bottom right.
          </>,
        image: <MooneraLogo />
      },
      {
        title: "Fill in your company information",
        content:
          <>
            Once you complete your infomration you will appear in the Moonera Directory and you will start getting reach outs. 
            <br/><br/> 
            You can fill in your company information in the 'Company Tab'.
            <br/>
          </>,
        icon: <PencilIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />
      },
      {
        title: "Manage your account",
        content:
        <> 
          From here you will be able to change your password and other account details.
          <br/><br/> 
          You can manage your account from the 'Account Tab'.
          <br/>
        </>,
        icon: <UserIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />
      },
      {
        title: "Moonera Directory",
        content:
          "This is where you company will appear. To access it, click in the Moonera Logo in the top section.",
        icon: <SearchIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />
      },
    ],
    "intro"
  );

  useEffect(() => {
    const tourCompleted = getTourCompletedFromLocalStorage();
    if (JSON.stringify(tourCompleted) === '{}') {
      setOpenTour(true);
    }
    setCapabilities("Arts & Crafts");
    setLocation("Canada")
    setBusinessType("Small Business");
    setSector("Agriculture / Artisan");

    dashboardCompanyService.getCompany().then(
      (response) => {
        const company = response.company;
        if (company !== null && company !== undefined) {
          setName(company.name);
          setEmail(company.email);
          setDisplayLogo(company.logo_url);
          setLocation(company.headquarters_location);
          setBusinessType(company.size);
          setPhoneNumber(company.phone_number);
          setSector(company.sector);
          setCapabilities(company.capabilities);
          setDescription(company.description);
          setPatents(company.patents);
          setTrademarks(company.trademarks);
          setCertifications(company.certifications);
          setBusinessHours(company.business_hours);
          setYearEstablished(company.year_established);
          setActionState('update');
        }
      },
      (_error) => {}
    );
  }, []);

  const dispatch = useDispatch();
  const onNameChange = (e: any) => {
    const name = e.target.value;
    setName(name);
  };
  const onDescriptionChange = (e: any) => {
    const description = e.target.value;
    setDescription(description);
  };
  const onEmailChange = (e: any) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onPhoneNumberChange = (e: any) => {
    const phoneNumber = e.target.value;
    setPhoneNumber(phoneNumber);
  };
  const onSectorChange = (e: any) => {
    const sector = e.target.value;
    setSector(sector);
  };
  const onCapabilitiesChange = (e: any) => {
    const capabilities = e.target.value;
    setCapabilities(capabilities);
  };
  const onCertificationsChange = (e: any) => {
    const certifications = e.target.value;
    setCertifications(certifications);
  };
  const onPatentsChange = (e: any) => {
    const patents = e.target.value;
    setPatents(patents);
  };
  const onTrademarksChange = (e: any) => {
    const trademarks = e.target.value;
    setTrademarks(trademarks);
  };
  const onBusinessHoursChange = (e: any) => {
    const businessHours = e.target.value;
    setBusinessHours(businessHours);
  };
  const onBusinessTypeChange = (e: any) => {
    const businessType = e.target.value;
    setBusinessType(businessType);
  };
  const onYearEstablishedChange = (e: any) => {
    const yearEstablished = e.target.value;
    setYearEstablished(yearEstablished);
  };
  const onLocationChange = (e: any) => {
    const location = e.target.value;
    setLocation(location);
  };
  const onLogoChange = (e: any) => {
    if ( e.target.files == null ) {
      throw new Error("Error finding e.target.files"); 
    }
    const logo = e.target.files[0];
    setLogo(logo);
  };


  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("company[name]", name);
    formData.append("company[email]", email);
    formData.append("company[headquarters_location]", location);
    formData.append("company[size]", businessType);
    formData.append("company[phone_number]", phoneNumber);
    formData.append("company[sector]", sector);
    formData.append("company[capabilities]", capabilities);
    formData.append("company[description]", description);
    formData.append("company[certifications]", certifications);
    formData.append("company[patents]", patents);
    formData.append("company[trademarks]", trademarks);
    formData.append("company[business_hours]", businessHours);
    formData.append("company[year_established]", yearEstablished);
    console.log(logo);
    console.log(logo.length);
    if (
      logo !== undefined && 
      logo !== null
    ) formData.append("company[logo]", logo);

    let saveAction = createCompany(formData);
    if (saveActionState === 'update') {
      saveAction = updateCompany(formData);
    }

    dispatch<any>(saveAction)
      .then(() => {
      })
      .catch(() => {
      });
  };

  return (
    <>
      <form className="mx-16 mt-8 mb-8 space-y-8 divide-y divide-gray-200" action="#" method="POST" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5 text-left">
          <div>
            <MooneraText text={'Company'} level={'secondary'}/>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <MooneraText text={'From here you will be able to manage information related to your company. All this information is viewable by buyers.'} level={'p-secondary'}/>
            
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center w-32">
              <MooneraButton text={'Save'} level={'primary'} />
            </div>
            </div>
          </div>

          <MooneraTextInput 
            label={'Company name'}
            htmlFor={'name'}
            id={'name'}
            name={'name'}
            type={'text'}
            required={true}
            value={name}
            onChange={onNameChange}
            styleType={'labels-on-left'}
          />

          <MooneraFileInput 
            label={'Logo'}
            htmlFor={'logo'}
            id={'logo'}
            name={'logo'}
            required={false}
            onChange={onLogoChange}
            styleType={'labels-on-left'}
            originalFile={displayLogo}
          />

          <MooneraTextArea
            label={'Company Description'}
            htmlFor={'description'}
            id={'description'}
            name={'description'}
            required={true}
            value={description}
            onChange={onDescriptionChange}
            styleType={'labels-on-left'}
          />
          <MooneraTextInput 
            label={'Contact Email. This email will be shared with potential buyers.'}
            htmlFor={'email'}
            id={'email'}
            name={'email'}
            required={true}
            type={'email'}
            value={email}
            onChange={onEmailChange}
            styleType={'labels-on-left'}
          />
          <MooneraTextInput 
            label={'Phone number. This phone number will be shared with potential buyers.'}
            htmlFor={'phone-number'}
            id={'phone-number'}
            name={'phone-number'}
            required={true}
            type={'text'}
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            styleType={'labels-on-left'}
          />

          <MooneraInputSelector 
            label={'Headquarters Location'}
            htmlFor={'location'}
            id={'location'}
            name={'location'}
            value={location}
            onChange={onLocationChange}
            options={['Canada', 'United States', 'Mexico']}
          />
          
          <MooneraInputSelector 
            label={'Business Type'}
            htmlFor={'business-type'}
            id={'business-type'}
            name={'business-type'}
            value={businessType}
            onChange={onBusinessTypeChange}
            options={['Small Business', 'Midmarket Business', 'Publicly-Traded Company']}
          />

          <MooneraInputSelector 
            label={'Industry'}
            htmlFor={'sector'}
            id={'sector'}
            name={'sector'}
            value={sector}
            onChange={onSectorChange}
            options={['Agriculture / Artisan', 'Apparel', 'Automotive', 'Beauty', 'Business Services', 'Chemicals', 'Commercial', 'Construction', 'Consumer Goods', 'Consumer Electronics', 'Distribution', 'Electrical', 'Electronics', 'Energy', 'Environment', 'Food & Beverage', 'Furniture', 'Hardware', 'Health & Medical', 'Home Appliances', 'Home & Garden', 'Instruments', 'Jewelry', 'Logistics', 'Machinery', 'Manufacturing', 'Mining', 'Office & School Supplies', 'Packaging & Printing', 'Raw Material', 'Real Estate', 'Renewables', 'Plastics', 'Security', 'Safety', 'Shoes', 'Sports & Entertainment', 'Testing', 'Toys & Hobbies']}
          />

          <MooneraInputSelector 
            label={'Product Categories'}
            htmlFor={'capabilities'}
            id={'capabilities'}
            name={'capabilities'}
            value={capabilities}
            onChange={onCapabilitiesChange}
            options={['Arts & Crafts', 'Apparel', 'Bags, Luggage & Cases', 'Beauty & Personal Care', 'Business Services / Chemicals', 'Commerical Equipment', 'Construction & Real Estate', 'Consumer Goods', 'Consumer Electronics', 'Electrical Equipment & Supplies', 'Electronic Components & Accessories', 'Energy Chemicals', 'Environment / Fabric & Textiles', 'Fabrication Services / Fashion Accessories', 'Food & Beverage', 'Furniture', 'Health & Medical', 'Home Appliances', 'Home Textiles', 'Home & Garden', 'Instruments & Equipment', 'Jewelry', 'Machinery', 'Metals' , 'Office & School Supplies', 'Packaging & Printing', 'Raw Material', 'Renewable Energy', 'Rubber & Plastics', 'Security', 'Safety', 'Shoes & Wearables', 'Sports & Entertainment', 'Testing', 'Tools & Hardware', 'Toys & Hobbies', 'Vehicles & Transportation', 'Vehicle Parts & Accessories']}
          />

          <MooneraTextInput 
            label={'Year Established'}
            htmlFor={'yearEstablished'}
            id={'yearEstablished'}
            name={'yearEstablished'}
            required={false}
            type={'text'}
            value={yearEstablished}
            onChange={onYearEstablishedChange}
            styleType={'labels-on-left'}
          />

          <MooneraTextArea
            label={'Business Hours'}
            htmlFor={'businessHours'}
            id={'businessHours'}
            name={'businessHours'}
            required={false}
            value={businessHours}
            onChange={onBusinessHoursChange}
            styleType={'labels-on-left'}
          />

          <MooneraTextArea
            label={'Certifications'}
            htmlFor={'certifications'}
            id={'certifications'}
            name={'certifications'}
            required={false}
            value={certifications}
            onChange={onCertificationsChange}
            styleType={'labels-on-left'}
          />

          <MooneraTextArea
            label={'Patents'}
            htmlFor={'patents'}
            id={'patents'}
            name={'patents'}
            required={false}
            value={patents}
            onChange={onPatentsChange}
            styleType={'labels-on-left'}
          />

          <MooneraTextArea
            label={'Trademarks'}
            htmlFor={'trademarks'}
            id={'trademarks'}
            name={'trademarks'}
            required={false}
            value={trademarks}
            onChange={onTrademarksChange}
            styleType={'labels-on-left'}
          />
        </div>
      </form>
      <MooneraGuidedPopOver 
        content={currStep.content}
        title={currStep.title}
        icon={currStep.icon}
        image={currStep.image}
        openTour={openTour}
        setOpenTour={setOpenTour}
        prevStep={prevStep}
        nextStep={nextStep}
        stepNum={stepNum}
        totalSteps={4}
        markTourAsDone={markTourAsDone}
      /> 

      <div className="fixed bottom-5 right-5 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
        <button onClick={() => {
          setOpenTour(true);
          reStartTour();
          }}>
          <QuestionMarkCircleIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />  
        </button> 
      </div> 
    </>
  );
}