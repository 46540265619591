import { useEffect, useState } from "react";
import companiesService from "../services/companies.service";
import { useParams } from "react-router-dom";
import { ICompany } from "../interfaces/ICompany";
import { PhoneIcon, MailIcon } from "@heroicons/react/solid";
import useDocumentTitle from "../hooks/useDocumentTitle";

export default function CompanyScreen() {
  let { companyID, companyName } = useParams();
  useDocumentTitle(companyName ?? 'Moonera');
  useEffect(() => {
    companiesService.getCompany(companyID ?? "").then(
      (response) => {
        setCompany(response.company);
      },
      (error) => {
        // dispatch error alert
      }
    );
  });
  const [company, setCompany] = useState<ICompany | undefined>(undefined);

  if (company === undefined) {
    return <></>;
  }

  return (
    <div className="mb-10 relative z-0 flex flex-1 overflow-hidden">
      <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
        <article>
          {/* company header */}
          <div>
            <div>
              <img
                className="h-32 w-full object-cover lg:h-48"
                src={company.logo_url}
                alt=""
              />
            </div>
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                <div className="flex">
                  <img
                    className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                    src={company.logo_url}
                    alt=""
                  />
                </div>
                <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                  <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                    <h1 className="truncate text-2xl font-bold text-gray-900">
                      {company.name}
                    </h1>
                    <h3 className="truncate font-bold text-gray-700">
                      {company.sector} | {company.capabilities}
                    </h3>
                  </div>
                  <div className="justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <a
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                      href={`mailto:${company.email}`}
                    >
                      <MailIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Email</span>
                    </a>

                    <a
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                      href={`tel:${company.phone_number}`}
                    >
                      <PhoneIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Call</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                <h1 className="truncate text-2xl font-bold text-gray-900">
                  {company.name}
                </h1>
                <h3 className="truncate font-bold text-gray-700">
                  {company.sector} | {company.capabilities}
                </h3>
              </div>
            </div>
          </div>

          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {company.location && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Location
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {company.location}
                  </dd>
                </div>
              )}

              {company.year_established && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Year Established
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {company.year_established}
                  </dd>
                </div>
              )}

              {company.business_hours && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Business Hours
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {company.business_hours}
                  </dd>
                </div>
              )}

              {company.trademarks && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Trademarks
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {company.trademarks}
                  </dd>
                </div>
              )}

              {company.patents && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Patents</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {company.patents}
                  </dd>
                </div>
              )}

              {company.certifications && (
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Certifications
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {company.certifications}
                  </dd>
                </div>
              )}

              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd className="mx-auto mt-1 max-w-prose space-y-5 text-sm text-gray-900">
                  {company.description}
                </dd>
              </div>
            </dl>
          </div>
        </article>
      </main>
    </div>
  );
}
