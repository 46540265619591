import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR,
} from "./types";
import { Dispatch } from 'redux';
import dashboardProductsService from "../services/dashboard-products.service";

export const createProduct = (name: string, description: string, minimum_order_quantity: string, starting_price: string, status:string) => (dispatch: Dispatch) => {
  return dashboardProductsService.createProduct(name, description, minimum_order_quantity, starting_price, status).then(
    (_response: any) => {
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Your product was created and it is visible on the site!",
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
    },
    (error: any) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      dispatch({
        type: ALERT_ERROR,
        payload: message,
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.reject();
    }
  );
};

export const updateProduct = (id:string, name: string, description: string, minimum_order_quantity: string, starting_price: string, status:string) => (dispatch: Dispatch) => {
  return dashboardProductsService.updateProduct(id, name, description, minimum_order_quantity, starting_price, status).then(
    (_response: any) => {
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Your product was updated and it is visible on the site!",
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
    },
    (error: any) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      dispatch({
        type: ALERT_ERROR,
        payload: message,
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.reject();
    }
  );
};


