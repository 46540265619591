import {useEffect, useState} from 'react';
import productsService from "../services/products.service";
import { useParams } from 'react-router-dom';
import { IProduct } from '../interfaces/IProduct';
import MooneraProduct from '../components/MooneraProduct';

export default function ProductScreen() {
  let { productID } = useParams();
  useEffect(() => {
    productsService.getProduct(productID ?? '').then(
      (response) => {
        setProduct(response.product);
      },
      (error) => {
        // dispatch error alert
      }
    );
  });
  const [product, setProduct] = useState<IProduct | undefined >(undefined);

  if (product === undefined) {
    return ( 
      <></>
    );
  }

  return ( 
    <MooneraProduct product={product} />
  );
}