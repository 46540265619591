import axios from "axios";
import { API_URL } from "../helpers/api-url";

const register = (email: string, password: string) => {
  return axios
    .post(API_URL + "/users", {
      user: {
        email: email,
        password: password
      }
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const login = (email: string, password: string) => {
  return axios
    .post(API_URL + "/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const requestPasswordReset = (email: string) => {
  return axios
    .post(API_URL + "/password_reset_request", {
      email: email,
    })
    .then((response) => {
      return response.data;
    });
};

const resetPassword = (password: string, token: string) => {
  return axios
    .post(API_URL + "/password_reset", {
      password: password,
      token: token,
    })
    .then((response) => {
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
  register,
  requestPasswordReset,
  resetPassword,
};