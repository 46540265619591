import MooneraMiniProductCard from '../components/MooneraMiniProductCard';
import { IProduct } from '../interfaces/IProduct';

interface IProps {
  products: IProduct[],
}

const ProductsScreen: React.FC<IProps> = ({ products }) => {
  return ( 
      <div className="max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8">
          <div className="-mx-px border-l border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
            {products.map((product: IProduct) => (
              <MooneraMiniProductCard 
                name={product.name} 
                main_photo={product.main_photo}
                starting_price={product.starting_price}
                key={product.id}
                id={product.id}
              />
            ))}
           
        </div>
      </div>
  );
}

export default ProductsScreen;