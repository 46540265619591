import axios from "axios";
import { API_URL } from "../helpers/api-url";

const getAllCompanies = () => {
  return axios
    .get(API_URL + "/companies")
    .then((response) => {
      return response.data;
    });
};

const companySearch = (text: string, capabilities: any, sectors: any) => {
  return axios
    .get(API_URL + `/company_search?text=${text}&capabilities=${capabilities}&sectors=${sectors}`)
    .then((response) => {
      return response.data;
    });
};

const companySearchFilters = () => {
  return axios
    .get(API_URL + `/company_search_filters`)
    .then((response) => {
      return response.data;
    });
};

const companyFiltersSearch = (capabilities: any, sectors: any) => {
  if (capabilities?.length === 0 && sectors?.length === 0 ) {
    return getAllCompanies();
  }
  return axios
    .get(API_URL + `/company_search`, { params: { capabilities, sectors } })
    .then((response) => {
      return response.data;
    });
};

const getCompany = (companyID: string) => {
  return axios
    .get(API_URL + (`/companies/${companyID}`  ))
    .then((response) => {
      return response.data;
    });
};


export default {
  getAllCompanies,
  companySearch,
  companyFiltersSearch,
  companySearchFilters,
  getCompany,
}
