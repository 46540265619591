import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR,
  ALERT_INFO,
} from "./types";
import AuthService from "../services/auth.service";
import { Dispatch } from 'redux';

export const register = (email: string, password: string) => (dispatch: Dispatch) => {
  return AuthService.register(email, password).then(
    (response: any) => {
     
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: response },
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Your account was created successfully!",
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
    },
    (error: any) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: REGISTER_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: message,
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.reject();
    }
  );
};

export const login = (username: string, password:string) => (dispatch: Dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      if (data.error !== null && data.error !== undefined) {
        const message =
          (data.error.response &&
            data.error.response.data &&
            data.error.response.data.message) ||
          data.error.message ||
          data.error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: message,
        });
        setTimeout(() => {
          dispatch({
            type: ALERT_CLEAR,
          });
        }, 5000);

        return Promise.reject();
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Login success",
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
      
    }
  );
};

export const requestPasswordReset = (username: string) => (dispatch: Dispatch) => {
  return AuthService.requestPasswordReset(username).then(
    (data) => {
      if (data.error !== null && data.error !== undefined) {
        dispatch({
          type: ALERT_INFO,
          payload: "Something went wrong. Please try agaian later.",
        });
        setTimeout(() => {
          dispatch({
            type: ALERT_CLEAR,
          });
        }, 5000);

        return Promise.reject();
      }

      dispatch({
        type: ALERT_INFO,
        payload: "We will send instructions on how to reset your password if we find a user with your email.",
      });

      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
      
    }
  );
};

export const resetPassword = (password: string, token: string) => (dispatch: Dispatch) => {
  return AuthService.resetPassword(password, token).then(
    (data) => {
      if (data.error !== null && data.error !== undefined) {
        dispatch({
          type: ALERT_INFO,
          payload: "Something went wrong. Please try agaian later.",
        });
        setTimeout(() => {
          dispatch({
            type: ALERT_CLEAR,
          });
        }, 5000);

        return Promise.reject();
      }

      dispatch({
        type: ALERT_SUCCESS,
        payload: "Password reset successful!",
      });

      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
      
    }
  );
};
export const logout = () => (dispatch: Dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};