import axios from "axios";
import { API_URL } from "../helpers/api-url";
import authHeader from "../helpers/auth-header";

const getAllProducts = () => {
  return axios
    .get(API_URL + "/dashboard/products", { headers: authHeader()  })
    .then((response) => {
      return response.data;
    });
};

const getProduct = (productID: string) => {
  return axios
    .get(API_URL + (`/dashboard/products/${productID}`  ), { headers: authHeader()  })
    .then((response) => {
      return response.data;
    });
};

const createProduct = (name: string, description: string, minimum_order_quantity: string, starting_price: string, status: string) => { 
  return axios
    .post(API_URL + "/dashboard/products/", {
      product: {
        name: name,
        description: description,
        minimum_order_quantity: minimum_order_quantity,
        starting_price: starting_price,
        main_photo: "https://photo.url/", 
        categories: "Category",
        status: status
      }, 
    }, { headers: authHeader()  })
    .then((response) => {
      return response.data;
    });
};

const updateProduct = (id: string, name: string, description: string, minimum_order_quantity: string, starting_price: string, status: string) => { 
  return axios
    .put(API_URL + `/dashboard/products/${id}`, {
      product: {
        name: name,
        description: description,
        minimum_order_quantity: minimum_order_quantity,
        starting_price: starting_price,
        main_photo: "https://photo.url/", 
        categories: "Category",
        status: status
      }, 
    }, { headers: authHeader()  })
    .then((response) => {
      return response.data;
    });
};

export default {
  getAllProducts,
  createProduct,
  getProduct,
  updateProduct
}
