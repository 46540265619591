import React from "react";
import { Disclosure } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useDispatch } from "react-redux";
import { Navigate } from 'react-router-dom';
import { logout } from "../../actions/auth";
import { useAuth } from "../../hooks/useAuth";
import {useLocation } from "react-router-dom";
import MooneraLogo from "../MooneraLogo";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}


const MooneraDashboardNavigation: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();

  const dispatch = useDispatch();

  const handleLogout = (e: any) => {
    e.preventDefault();
    const logoutAction = logout();
    dispatch<any>(logoutAction)
      .then(() => {
        return <Navigate to="/signin" />;
      })
      .catch(() => {});
  };

  const navigation = [
    { name: 'Company', href: '/dashboard/company', current: location.pathname === '/dashboard/company' },
    { name: 'Account', href: '/dashboard/account', current: location.pathname === '/dashboard/account' },
    { name: 'Products (Beta)', href: '/dashboard', current: location.pathname === '/dashboard' },
  ];

  return ( <Disclosure as="header" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
            <div className="relative h-16 flex justify-between">
              <div className="relative z-10 px-2 flex lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <MooneraLogo />
                </div>
              </div>
              <div className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0">
                <nav className="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </a>
                  ))}                 
                </nav>
              </div>
              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                
                { auth ?
                <button
                  type="button"
                  className="flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleLogout}
                >
                  Log out
                </button> :   
                <button
                  type="button"
                  className="flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
                ><a href="/signin">Sign in</a> </button>}
                 
              </div>
            </div>
            
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="pt-2 pb-3 px-2 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'block rounded-md py-2 px-3 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              { auth ?
                <Disclosure.Button
                  className="flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleLogout}>
                  Log out
                </Disclosure.Button> : <Disclosure.Button
                  className="flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <a href="/signin">Sign in</a>
                </Disclosure.Button>
              }              
            </div>     
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default MooneraDashboardNavigation;
