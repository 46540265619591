import React from "react";

interface IProps {
  textComponent: React.ReactNode
  primaryCTAComponent: React.ReactNode
}

const MooneraEmptyState: React.FC<IProps> = ({textComponent, primaryCTAComponent}) => {

  return (
    <div className="max-w-2xl mx-auto text-center mt-8">
      {textComponent}
      <div className="mt-6 w-48 mx-auto">
        {primaryCTAComponent}
      </div>
    </div>
  );
};


export default MooneraEmptyState;
