import React from "react";

type StyleType = 
  | 'carded'
  | 'labels-on-left'

interface IProps {
  label: string;
  htmlFor: string;
  id: string;
  name: string;
  required?: boolean;
  onChange?: any;
  styleType?: StyleType;
  disabled?: boolean;
  originalFile?: string;
}

const MooneraFileInput: React.FC<IProps> = ({ label, id, name, onChange=null, required=false, htmlFor, styleType='carded', disabled=false, originalFile }) => {

  switch (styleType) {
    case 'carded':
      return (
        <div>
          <label htmlFor={htmlFor} className="text-left block text-sm font-medium text-gray-700">
            {label}  
          </label>
          <div className="mt-1">
            <input
              id={id}
              name={name}
              type="file"
              required={required}
              onChange={onChange}
              disabled={disabled}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      );
    case 'labels-on-left':
      return (
        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {label}  
            </label>
            <div className="flex flex-row mt-1 sm:mt-0 sm:col-span-2">
              {originalFile && <img className="w-14 h-14 mr-4" src={originalFile} alt="logo"/>}
              <input
                id={id}
                name={name}
                type="file"
                required={required}
                onChange={onChange}
                disabled={disabled}
                className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      );
  } 
};


export default MooneraFileInput;
