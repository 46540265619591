import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  name: string, 
  starting_price: number,
  main_photo: string,
  key: string,
  id: string
}
const MooneraMiniProductCard: React.FC<IProps> = ({ name, starting_price, main_photo, key, id }) => {
  return ( 
    <div key={key} className="group relative p-4 border-r border-b border-gray-200 sm:p-6">
      <div className="rounded-lg overflow-hidden bg-gray-200 aspect-w-1 aspect-h-1 group-hover:opacity-75">
        <img
          src={main_photo}
          alt={name}
          className="w-full h-full object-center object-cover"
        />
      </div>
    
      <div className="pt-10 pb-4 text-center">
        <h3 className="text-sm font-medium text-gray-900">
          <Link to={`/products/${id}/`}>
            <span aria-hidden="true" className="absolute inset-0" />
              {name}
          </Link>
        </h3>
                
        <p className="mt-4 text-base font-medium text-gray-900">${starting_price} USD</p>
      </div>
    </div>
  );
};

export default MooneraMiniProductCard;
