import React, { useEffect, useState } from "react";

interface Steps {
  title: string;
  content: React.ReactNode;
  icon?: React.ReactNode;
  image?: React.ReactNode;
}

const localStorageKey = "moonera-onboarding-tour";

export const useOnboardingTour = (steps: Steps[], key: string) => {
  const [stepNum, setStep] = useState(0);
  useEffect(() => {
    const tourCompleted = getTourCompletedFromLocalStorage();

    if (!tourCompleted) {
      saveInitialTourCompletedInLocalStorage();
      startTour();
      return;
    }

    if (!tourCompleted[key]) {
      startTour();
      return;
    }

  }, [key]);

  const getTourCompletedFromLocalStorage = () => {
    const tourCompleted = localStorage.getItem(localStorageKey);
    if (!tourCompleted) {
      return null;
    }

    return JSON.parse(tourCompleted);
  };

  const saveFinishedInLocalStorage = () => {
    const tourCompleted = getTourCompletedFromLocalStorage();
    tourCompleted[key] = true;
    localStorage.setItem(localStorageKey, JSON.stringify(tourCompleted));
  };

  const saveInitialTourCompletedInLocalStorage = () => {
    localStorage.setItem(localStorageKey, JSON.stringify({}));
  };

  const nextStep = () => {
    setStep((step) => step + 1);
  };

  const prevStep = () => {
    setStep((step) => step - 1);
  };

  const startTour = () => {
    setStep(0);
  };

  const markTourAsDone = () => {
    saveFinishedInLocalStorage();
  };

  const reStartTour = () => {
    setStep(0);
  };

  return {
    stepNum,
    prevStep,
    nextStep,
    startTour,
    markTourAsDone,
    reStartTour,
    getTourCompletedFromLocalStorage,
    currStep: steps[stepNum] || { title: "", content: "", icon: undefined, image: undefined },
  };
};
