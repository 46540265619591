import React from "react";
import { IProduct } from '../interfaces/IProduct';

interface IProps {
  product: IProduct
}
const MooneraProduct: React.FC<IProps> = ({ product }) => {
  return ( 
      <div className="max-w-2xl mx-auto px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
        {/* Product details */}
        <div className="">
          <h1 className="text-3xl text-left font-extrabold tracking-tight text-gray-900 sm:text-4xl">{product.name}</h1>

          <section aria-labelledby="information-heading" className="mt-4">
            <h2 id="information-heading" className="sr-only">
              Product information
            </h2>
            <div className="flex items-center mx-auto ">
              <p className="text-lg text-gray-900 sm:text-xl">${product.starting_price} USD</p>
               <div className="ml-4 pl-4 border-l border-gray-300">
                  <p className="text-lg text-gray-900 sm:text-xl">{product.minimum_order_quantity} units minimum order quantity</p>
                </div>
            </div>

            <div className="mt-4 space-y-6 text-left">
              <p className="text-base text-gray-500">{product.description}</p>
            </div>

           
          </section>
        </div>

        {/* Product image */}
        <div className=" lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
          <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden">
            <img src={product.main_photo} alt={product.name} className="w-full h-full object-center object-cover" />
          </div>
        </div>

        <div className="mt-10">
          <button
            type="submit"
            className="w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
          >
          Contact seller to buy
          </button>
        </div>
      </div>
  );
};

export default MooneraProduct;
