import React from "react";

export type levelType = 
  | 'primary'
  | 'secondary'
  
interface IProps {
  text: string;
  level: levelType;
  onClick?: any;
}

const MooneraButton: React.FC<IProps> = ({ text, level = 'secondary', onClick=null }) => {

  switch (level) {
    case 'primary':
      return (
        <button type="submit" onClick={onClick} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {text} 
        </button>
      );
    case 'secondary':
      return (
        <button type="submit" onClick={onClick} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          {text} 
        </button>
      );
  
    default: 
      return (
        <button type="submit" onClick={onClick} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {text} 
        </button>)
  }
};


export default MooneraButton;
