import React from "react";
import { Link } from "react-router-dom";
import Logo from "../img/moonera-latam-sourcing-find-verified-latam-suppliers-logo.png";

const MooneraLogo: React.FC = () => {

  return (
    <Link to="/">
      <img
        className="mx-auto h-12 w-auto"
        src={Logo}
        alt="Moonera - Verified LATAM Suppliers"
      />
    </Link>
    
  );
};


export default MooneraLogo;
