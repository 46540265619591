import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { createProduct } from "../../../actions/products";
import MooneraButton from '../../../components/MooneraButton';
import MooneraText from '../../../components/MooneraText';
import MooneraTextArea from "../../../components/MooneraTextArea";
import MooneraTextInput from '../../../components/MooneraTextInput';
import MooneraPageHeader from "../../../components/MooneraPageHeader";
import dashboardCompanyService from "../../../services/dashboard-company.service";

export default function DashboardProductNew() {
  const [company, setCompany] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    dashboardCompanyService.getCompany().then(
      (response) => {
        setCompany(response.company);
      },
      (_error) => {}
    );
  });

  if (company == null) {
    navigate('/dashboard/company');
  }
  

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
 

  const dispatch = useDispatch();
  const onNameChange = (e: any) => {
    const name = e.target.value;
    setName(name);
  };
  const onDescriptionChange = (e: any) => {
    const description = e.target.value;
    setDescription(description);
  };
  const onStartingPriceChange = (e: any) => {
    const startingPrice = e.target.value;
    setStartingPrice(startingPrice);
  };
  const onMinimumOrderQuantityChange = (e: any) => {
    const minimumOrderQuantity = e.target.value;
    setMinimumOrderQuantity(minimumOrderQuantity);
  };
  
  const handleCreation = (e: any) => {
    e.preventDefault();
    const createAction = createProduct(name, description, minimumOrderQuantity, startingPrice, 'published')
    dispatch<any>(createAction)
      .then(() => {
        navigate('/dashboard');
      })
      .catch(() => {
      });  
  };

  const handleDraftCreation = (e: any) => {
    e.preventDefault();
    const createAction = createProduct(name, description, minimumOrderQuantity, startingPrice, 'draft')
    dispatch<any>(createAction)
      .then(() => {
        navigate('/dashboard');
      })
      .catch(() => {
      });  
  };
  
  return ( 
    <>
      <form className="mx-8 mt-8 space-y-8 " action="#" method="POST">
        <div className=" sm:space-y-5 text-left">
          <div>
            <MooneraText text={'New Product'} level={'secondary'}/>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <MooneraText text={'Fill in your product details.'} level={'p-secondary'}/>
                <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center w-80">
                  <MooneraButton onClick={handleDraftCreation} text={'Save Draft'} level={'secondary'} />
                  <MooneraButton onClick={handleCreation} text={'Save & Publish'} level={'primary'} />
                </div>
              </div>
            </div>
                 
              <MooneraTextInput 
                label={'Product name'}
                htmlFor={'name'}
                id={'name'}
                name={'name'}
                type={'text'}
                required={true}
                value={name}
                onChange={onNameChange}
                styleType={'labels-on-left'}
              />
              <MooneraTextArea
                label={'Description'}
                htmlFor={'description'}
                id={'description'}
                name={'description'}
                required={true}
                value={description}
                onChange={onDescriptionChange}
                styleType={'labels-on-left'}
              />
              <MooneraTextInput 
                label={'Mimum order quantity'}
                htmlFor={'minimum-order-qty'}
                id={'minimum-order-qty'}
                name={'minimum-order-qty'}
                type={'number'}
                required={true}
                value={minimumOrderQuantity}
                onChange={onMinimumOrderQuantityChange}
                styleType={'labels-on-left'}
              />
              <MooneraTextInput 
                label={'Starting price'}
                htmlFor={'starting-price'}
                id={'starting-price'}
                name={'starting-price'}
                type={'number'}
                required={true}
                value={startingPrice}
                onChange={onStartingPriceChange}
                styleType={'labels-on-left'}
              />
          
        </div>
    </form>
    </>
  );
}