import {
  ALERT_SUCCESS,
  ALERT_ERROR,
  ALERT_CLEAR,
} from "./types";
import { Dispatch } from 'redux';
import dashboardCompanyService from "../services/dashboard-company.service";

export const createCompany = (formData: any) => (dispatch: Dispatch) => {
  return dashboardCompanyService.createCompany(formData).then(
    (_response: any) => {
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Your company was created",
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
    },
    (error: any) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      dispatch({
        type: ALERT_ERROR,
        payload: message,
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.reject();
    }
  );
};

export const updateCompany = (formData: any) => (dispatch: Dispatch) => { 
  return dashboardCompanyService.updateCompany(formData).then(
    (_response: any) => {
      dispatch({
        type: ALERT_SUCCESS,
        payload: "Your company information was updated.",
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.resolve();
    },
    (error: any) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      dispatch({
        type: ALERT_ERROR,
        payload: message,
      });
      setTimeout(() => {
        dispatch({
          type: ALERT_CLEAR,
        });
      }, 5000);
      return Promise.reject();
    }
  );
};


